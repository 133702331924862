import { axiosDispatch, buildQuery } from '@/utils'
import moment from 'moment-timezone'

const baseUrl = 'https://gestao-operacional.acordo.app/' // 'http://localhost:3002/'
const todoListBff = 'api/todo-list-bff/v1/todo-list'

const dashboardActions = {
  getDashboard({ state }, chartName) {
    let url = 'api/disputes/dashboard?'
    if (chartName) {
      url += `chartName=${chartName}&`
    } else {
      for (const chart of state.charts) {
        url += `chartName=${chart}&`
      }
    }
    if (state.selectedMemberId !== null) {
      url += `memberId=${state.selectedMemberId}&`
    }
    url += 'reload=true'

    return axiosDispatch({
      url,
      mutation: 'setDashboardDatasets'
    })
  },

  setTodoListSummaryMode({ commit, dispatch }, summaryMode) {
    dispatch('setAccountProperty', { TIDO_LIST_SUMMARY_MODE: summaryMode })
  },

  getTodoListSummary({ getters }, data) {
    const query = {
      negociadorId: getters.dashboardSelectedMemberId || (data?.summaryMode === 'DETAILD' ? getters.loggedPersonId : null),
      data: moment(data?.date || new Date()).format('YYYY-MM-DD'),
      workspaceId: getters.workspaceId
    }
    console.log('getTodoListSummary', query)
    console.log('getTodoListSummary', baseUrl)
    return axiosDispatch({
      url: `${baseUrl}${todoListBff}/summary${buildQuery(query)}`,
      // url: `http://localhost:3002${todoListBff}/summary${buildQuery(query)}`,
      mutation: 'setTodoListSummary'
    })
  }
}

export default dashboardActions
