const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "mainContainer" */ '@/views/main/MainContainer'),
    meta: {
      requiresAuth: true,
      trackPage: false,
      title: 'ProJuris'
    },
    children: [
      {
        name: 'dashboard',
        path: '/',
        component: () => import(/* webpackChunkName: "dashboardIndex" */ '@/views/main/dashboard/Dashboard'),
        meta: {
          requiresAuth: true,
          trackPage: true,
          title: 'Dashboard'
        }
      },
      {
        name: 'import',
        path: 'import',
        component: () => import(/* webpackChunkName: "importIndex" */ '@/views/main/import/Import'),
        meta: {
          requiresAuth: true,
          trackPage: true,
          title: 'Importação'
        }
      },
      {
        name: 'NewImport',
        path: 'import/new',
        component: () => import(/* webpackChunkName: "importNew" */ '@/views/main/import/NewImport'),
        meta: {
          requiresAuth: true,
          trackPage: true,
          title: 'Nova importação'
        }
      },
      {
        name: 'importLoading',
        path: 'import/loading',
        component: () => import(/* webpackChunkName: "importLoading" */ '@/views/main/import/Loading'),
        meta: {
          requiresAuth: true,
          trackPage: true,
          title: 'Carregando...'
        }
      },
      {
        name: 'management',
        path: '/management',
        component: () => import(/* webpackChunkName: "managementIndex" */ '@/views/main/negotiation/Negotiation'),
        meta: {
          requiresAuth: true,
          trackPage: true,
          title: 'Gerenciamento'
        }
      },
      {
        name: 'allDisputes',
        path: '/management/all',
        component: () => import(/* webpackChunkName: "managementAll" */ '@/views/main/negotiation/Negotiation'),
        meta: {
          requiresAuth: true,
          trackPage: true,
          title: 'Todas as disputas'
        }
      },
      {
        name: 'dispute',
        path: 'management/dispute/:id',
        redirect: ({ params: { id } }) => (`negotiation/${id}`),
        meta: {
          requiresAuth: true,
          trackPage: true,
          title: 'Disputa'
        }
      },
      {
        path: 'management/dispute/',
        redirect: {
          name: 'management',
          title: 'Disputa'
        }
      },
      {
        name: 'negotiation',
        path: 'negotiation/',
        component: () => import(/* webpackChunkName: "negotiationIndex" */ '@/views/main/negotiation/Negotiation'),
        meta: {
          hideFullHeader: true,
          requiresAuth: true,
          trackPage: true,
          title: 'Negociação'
        },
        children: [
          {
            name: 'ticket',
            path: ':id',
            component: () => import(/* webpackChunkName: "TicketIndex" */ '@/views/main/negotiation/partials/ticket/Ticket'),
            meta: {
              hideFullHeader: true,
              requiresAuth: true,
              trackPage: true,
              title: 'Negociação'
            }
          }
        ]
      },
      {
        name: 'print',
        path: 'print',
        redirect: 'negotiation',
        component: () => import(/* webpackChunkName: "print" */ '@/views/main/print/index'),
        children: [
          {
            name: 'printTicket',
            path: 'negotiation/:id',
            component: () => import(/* webpackChunkName: "printTicket" */ '@/views/main/print/Ticket'),
            meta: {
              requiresAuth: true,
              trackPage: true,
              title: 'Imprimir Negociação'
            }
          }
        ]
      },
      {
        name: 'Whatsapp',
        path: '/whatsapp',
        component: () => import(/* webpackChunkName: "whatsappViews" */ '@/views/main/watsapp/Views'),
        meta: {
          requiresAuth: true,
          trackPage: true,
          title: 'Templates do WhatsApp'
        }
      },
      {
        name: 'relatorios',
        path: 'reports',
        component: () => import(/* webpackChunkName: "ReportsList" */ '@/views/main/reports/ReportsList'),
        meta: {
          requiresAuth: true,
          trackPage: true,
          title: 'Relatórios'
        },
        children: [
          {
            name: 'managementReport',
            path: 'management',
            component: () => import(/* webpackChunkName: "ManagementReport" */ '@/views/main/reports/partials/Management'),
            meta: {
              requiresAuth: true,
              trackPage: true,
              title: 'Relatório Gerêncial'
            }
          },
          {
            name: 'managerialReport',
            path: 'managerial',
            component: () => import(/* webpackChunkName: "managerialReport" */ '@/views/main/reports/partials/Managerial'),
            meta: {
              requiresAuth: true,
              trackPage: true,
              title: 'Relatório Gestão'
            }
          }
        ]
      },
      {
        name: 'billingClientList',
        path: '/billing',
        component: () => import(/* webpackChunkName: "billingClientList" */ '@/views/main/billing/BillingClientList'),
        meta: {
          requiresAuth: true,
          trackPage: true,
          title: 'Financeiro'
        }
      },
      {
        name: 'billingDashboard',
        path: '/billing/:customerId',
        component: () => import(/* webpackChunkName: "billingDashboard" */ '@/views/main/billing/BillingDashboard'),
        meta: {
          requiresAuth: true,
          trackPage: true,
          title: 'Financeiro'
        }
      },
      {
        name: 'configurations',
        path: 'configurations/:tab',
        component: () => import(/* webpackChunkName: "configurationsIndex" */ '@/views/main/configurations/Configurations'),
        meta: {
          hideFullHeader: true,
          requiresAuth: true,
          trackPage: true,
          title: 'Configurações'
        }
      },
      {
        path: 'configurations',
        redirect: 'configurations/workspace-data'
      },
      {
        name: 'workspaces',
        path: 'workspaces',
        component: () => import(/* webpackChunkName: "workspaces" */ '@/views/main/configurations/partials/WorkspaceList'),
        meta: {
          requiresAuth: true,
          trackPage: true,
          title: 'Workspaces'
        }
      }
    ]
  },
  {
    name: 'admin-panel',
    path: '/admin-panel',
    component: () => import(/* webpackChunkName: "adminPanelUpdated" */ '@/views/adminPanel/AdminPanel'),
    meta: {
      requiresAuth: false,
      trackPage: true,
      title: 'Painel administrativo'
    }
  },
  {
    name: 'login',
    path: '/login',
    component: () => import(/* webpackChunkName: "externalIndex" */ '@/views/external/Login'),
    meta: {
      requiresAuth: false,
      trackPage: true,
      title: 'ProJuris - Login'
    }
  },
  {
    name: 'redirect',
    path: '/redirect',
    component: () => import(/* webpackChunkName: "redirect" */ '@/views/main/Redirect'),
    meta: {
      title: 'Redirecionando'
    }
  },
  {
    name: 'register',
    path: '/register',
    component: () => import(/* webpackChunkName: "register" */ '@/views/external/Register'),
    meta: {
      requiresAuth: false,
      trackPage: true,
      title: 'ProJuris - Cadastre-se'
    }
  },
  {
    name: 'forgot-password',
    path: '/forgot-password',
    component: () => import(/* webpackChunkName: "forgotPassword" */ '@/views/external/ForgotPassword'),
    meta: {
      requiresAuth: false,
      trackPage: true,
      title: 'ProJuris - Recuperar senha'
    }
  },
  {
    name: 'new-password',
    path: '/new-password/:token',
    component: () => import(/* webpackChunkName: "newPassword" */ '@/views/external/NewPassword'),
    meta: {
      requiresAuth: false,
      trackPage: true,
      title: 'ProJuris - Nova senha'
    }
  },
  {
    name: 'recover-account',
    path: '/recover-account',
    component: () => import(/* webpackChunkName: "recoverAccount" */ '@/views/external/RecoverAccount'),
    meta: {
      requiresAuth: false,
      trackPage: true,
      title: 'ProJuris - Recuperar senha'
    }
  },
  {
    name: 'onboarding',
    path: '/onboarding',
    component: () => import(/* webpackChunkName: "onboarding" */ '@/views/onboarding/Onboarding'),
    meta: {
      requiresAuth: true,
      trackPage: true,
      title: 'Onboarding'
    }
  },
  {
    name: 'icons',
    path: '/icons',
    component: () => import(/* webpackChunkName: "adminPanel" */ '@/views/icons/Icons'),
    meta: {
      title: 'ProJuris - Icons'
    }
  },
  {
    path: '*',
    redirect: '/'
  }
]

export default routes
